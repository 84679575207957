<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Ordina materiale</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>
                <!-- Commessa list based on selected customer -->
                <Transition name="fade-transition">
                    <div v-show="showProgetti" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQueryProgetti" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="progetto in searchedProgetti"
                                    :key="progetto.projects_id"
                                    @click="setSelectedProgetto(progetto)"
                                    class="customer"
                                    :class="setActiveProgetto(progetto, selectedProgetto)"
                                >
                                    {{ progetto.projects_name }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeProgettoSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>
                <!-- Prodotti list, triggered with click on select prodotto in form -->
                <Transition name="fade-transition">
                    <div v-show="showProdotti" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca materiale" v-model="searchQueryProdotti" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="prodotto in searchedProdotti"
                                    :key="prodotto.customers_id"
                                    @click="setSelectedProdotto(prodotto)"
                                    class="customer"
                                    :class="setActiveProdotto(prodotto, selectedProdotto)"
                                >
                                    {{ prodotto.fw_products_name }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeProdottoSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- Form ordine materiali -->
                <form @submit.prevent="ordinaMateriali()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Commessa
                                    </span>
                                    <div @click="openProgettoSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedProgetto">
                                        {{ selectedProgetto.projects_name }}
                                    </div>
                                    <div v-else>Nessuna commessa selezionata</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Prodotti -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Materiali
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </span>
                                    <div @click="openProdottoSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="ordine.prodotti.length != 0">
                                        <div class="container_prodotti">
                                            <div v-for="(prodotto, index) in ordine.prodotti" :key="index" class="prodotto">
                                                <div class="data">{{ prodotto.qty }} - {{ prodotto.name }}</div>
                                                <div class="actions">
                                                    <div @click="changeQuantity(prodotto, 'minus')" class="button button_minus">
                                                        -
                                                    </div>
                                                    <div @click="changeQuantity(prodotto, 'plus')" class="button button_plus">
                                                        +
                                                    </div>

                                                    <div @click="showActionSheet(prodotto)" class="button button_remove">
                                                        <ion-icon slot="start" :icon="close" color="red"></ion-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>Nessun materiale selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="submit" class="btn_crea_intervento" :disabled="isLoading">
                                Invia ordine materiali
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonText,
    /* IonInput,
    IonSelect,
    IonSelectOption, */
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline, close, trash } from "ionicons/icons";

import { defineComponent, ref, reactive, watch, computed, onMounted } from "vue";

import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import apiClienti from "@/services/clienti";
import apiProgetti from "@/services/progetti";
import apiProdotti from "@/services/prodotti";
import apiDocContabilita from "@/services/doc_contabilita";

import moment from "moment";

export default defineComponent({
    name: "OrdineMateriali",
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonText,
        /* IonInput,
        IonSelect,
        IonSelectOption, */
        IonIcon,
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfoAssitech")).dipendenti_id;

        const appuntamento = { ...props.data };

        const ordine = reactive({
            utente: userID,
            /*             cliente: appuntamento.appuntamenti_cliente,
            commessa: appuntamento.appuntamenti_impianto, */
            cliente: "813",
            commessa: "259",
            prodotti: [],
            tipo: "14",
        });

        const successResponse = ref(false);

        /**
         * ! Load prodotti
         */
        const prodotti = ref([]);
        async function loadProducts() {
            try {
                const res = await apiProdotti.getProdotti();
                if (res.status === 0) {
                    prodotti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei prodotti", "toast_danger");
            } finally {
                //console.log(customers.value);
            }
        }

        /**
         * ! Gestione PRODOTTI
         */
        const showProdotti = ref(false);
        function openProdottoSelection() {
            showProdotti.value = true;
        }

        function closeProdottoSelection() {
            showProdotti.value = false;
        }

        const searchQueryProdotti = ref("");
        const searchedProdotti = computed(() => {
            const term = searchQueryProdotti.value.replace(/ /g, "");
            return prodotti.value.filter((prodotto) => {
                if (prodotto.fw_products_name) {
                    return (
                        prodotto.fw_products_name
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                }
            });
        });

        const selectedProdotto = ref(null);
        function setSelectedProdotto(prodotto) {
            //console.log(prodotto);
            if (prodotto) {
                searchQueryProdotti.value = "";
                selectedProdotto.value = prodotto;
                //Se ho già aggiunto il prodotto devo aumentare al quantità di uno altrimenti lo inserisco
                const productExists = ordine.prodotti.find((product) => product.prodotto_id === prodotto.fw_products_id);

                if (productExists) {
                    productExists.qty += 1;
                } else {
                    ordine.prodotti.push({
                        name: prodotto.fw_products_name,
                        prodotto_id: prodotto.fw_products_id,
                        qty: 1,
                    });
                }
                //console.log(ordine.prodotti);
                showProdotti.value = false;
            }
        }

        const setActiveProdotto = computed(() => {
            return (prodotto, selectedProdotto) => {
                let className = "";
                if (selectedProdotto) {
                    if (prodotto.fw_products_id === selectedProdotto.fw_products_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! GESTIONE CLIENTE
         */

        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
            //selectedProgetto.value = false;
            ordine.commessa = null;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }
        /**
         * !Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            }
        }

        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return customers.value.filter((cliente) => {
                //company, nome/cognome, province
                if (cliente.customers_company) {
                    return (
                        cliente.customers_company
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else {
                    if (cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_last_name + cliente.customers_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_name + cliente.customers_last_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (cliente.customers_name && !cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (!cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    }
                }
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
                //imposta il cliente sul rimborso
                ordine.cliente = customer.customers_id;
            }
        }

        const setActiveCustomer = computed(() => {
            return (customer, selectedCustomer) => {
                let className = "";

                if (selectedCustomer) {
                    if (customer.customers_id === selectedCustomer.customers_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Get customer projects
         */
        const progetti = ref([]);
        async function loadProgetti(customer_id) {
            try {
                const res = await apiProgetti.getClienteCommesse(customer_id);
                if (res.status === 0) {
                    progetti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei progetti", "toast_danger");
                }
            } catch (error) {
                progetti.value = [];
                openToast("Errore durante la richiesta dei progetti", "toast_danger");
            }
        }
        /**
         * ! Watch customer selection to get sede and project data
         */

        watch(
            () => selectedCustomer.value,
            (newCustomer, oldCustomer) => {
                console.log("newCustomer", newCustomer); // => ref(undefined)
                //Get customer projects
                loadProgetti(newCustomer.customers_id);
            }
        );

        /**
         * ! GESTIONE PROGETTI
         */
        const showProgetti = ref(false);
        function openProgettoSelection() {
            showProgetti.value = true;
        }

        function closeProgettoSelection() {
            showProgetti.value = false;
        }

        const searchQueryProgetti = ref("");
        const searchedProgetti = computed(() => {
            const term = searchQueryProgetti.value.replace(/ /g, "");
            return progetti.value.filter((progetto) => {
                if (progetto.projects_name) {
                    return (
                        progetto.projects_name
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                }
            });
        });

        const selectedProgetto = ref(null);
        function setSelectedProgetto(progetto) {
            //console.log(progetto);
            if (progetto) {
                searchQuery.value = "";
                selectedProgetto.value = progetto;
                showProgetti.value = false;
                //imposta il cliente sul rimborso
                ordine.commessa = progetto.projects_id;
            }
        }

        const setActiveProgetto = computed(() => {
            return (progetto, selectedProgetto) => {
                let className = "";

                if (selectedProgetto) {
                    if (progetto.projects_id === selectedProgetto.projects_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Change quantity of selected product
         */
        function changeQuantity(prodotto, mode) {
            if (!prodotto) return;
            if (mode === "plus") {
                prodotto.qty += 1;
            }
            if (mode === "minus" && prodotto.qty > 1) {
                prodotto.qty -= 1;
            }
        }

        /**
         * ! Removed product from selected product
         */
        function removeProduct(prodotto) {
            ordine.prodotti = ordine.prodotti.filter((materiale) => materiale.prodotto_id != prodotto.prodotto_id);
        }

        /**
         * ! Handle delete single materiale
         */
        const showActionSheet = async (materiale) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            removeProduct(materiale);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * ! Limit prouduct name to 35 charactes
         */
        function setShortProduct(product_name) {
            if (!product_name) {
                return "-";
            } else {
                const trimmedString = product_name.length > 28 ? product_name.substring(0, 26) + "..." : product_name;
                return trimmedString;
            }
        }

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Create new ordine
         */
        const isLoading = ref(false);
        async function ordinaMateriali() {
            isLoading.value = true;
            //Controllo che ci sia il cliente
            if (!ordine.cliente) {
                openToast("Non puoi creare l'ordine senza selezionare il cliente", "toast_danger");
                return;
            }
            //Controllo che ci sia la commessa
            if (!ordine.commessa) {
                openToast("Non puoi creare l'ordine senza selezionare la commessa", "toast_danger");
                return;
            }
            //Controllo che ci sia almeno un prodotto
            if (ordine.prodotti.length == 0) {
                openToast("Non puoi creare l'ordine senza selezionare i materiali", "toast_danger");
                return;
            }
            //Controllo che ci sia il tipo di documento da creare
            if (!ordine.tipo) {
                openToast("Non puoi creare l'ordine senza selezionare la tipologia", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("utente", ordine.utente);
            data.append("tipo", ordine.tipo);
            data.append("cliente", ordine.cliente);
            data.append("commessa", ordine.commessa);
            data.append("prodotti", JSON.stringify(ordine.prodotti));

            // Display the values
            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            try {
                const response = await apiDocContabilita.saveOrdineMateriale(data);
                console.log(response);
                if (response.data === 0) {
                    successResponse.value = false;
                    openToast(response.txt, "toast_danger");
                } else {
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, response.data);
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la creazione dell'ordine", "toast_danger");
            } finally {
                isLoading.value = false;
            }
        }

        onMounted(() => {
            loadCustomer();
            loadProducts();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            trash,
            //appuntamento data
            appuntamento,
            //ordine
            ordinaMateriali,
            ordine,
            //Clienti
            customers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            searchQuery,
            searchedCustomers,
            setSelectedCustomer,
            selectedCustomer,
            setActiveCustomer,
            // Progetti
            searchQueryProgetti,
            searchedProgetti,
            showProgetti,
            openProgettoSelection,
            closeProgettoSelection,
            selectedProgetto,
            setActiveProgetto,
            setSelectedProgetto,
            // Prodotti
            searchQueryProdotti,
            searchedProdotti,
            showProdotti,
            openProdottoSelection,
            closeProdottoSelection,
            selectedProdotto,
            setActiveProdotto,
            setSelectedProdotto,
            //Handle quantity
            changeQuantity,
            //Remove product
            showActionSheet,
            setShortProduct,
            //loading
            isLoading,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
    opacity: 0.5;
}
ion-button {
    --color: #ffffff;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    margin-bottom: 16px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

/* .Prodotti selezionati */
.container_prodotti {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 12px;
}
.container_prodotti .prodotto {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
}
.container_prodotti .prodotto .data {
    font-size: 12px;
}

.container_prodotti .prodotto .actions {
    display: flex;
    margin-left: 16px;
}

.container_prodotti .prodotto .button {
    width: 22px;
    height: 22px;
    display: flex;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: baseline;
    background: #086fa3;
    color: white;
}
.container_prodotti .prodotto .button.button_plus {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.container_prodotti .prodotto .button.button_minus {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.container_prodotti .prodotto .button.button_remove {
    width: 22px;
    height: 22px;
    display: flex;
    margin-left: 4px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    background: #c1162d;
    color: white;
}
</style>
